import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {Field, reduxForm, getFormValues} from 'redux-form';

import {Grid, Typography} from '@material-ui/core';

import SaveIcon from '@material-ui/icons/Save';

import MainContainer from '../../../../../organisms/MainContainer.js';
import PaperPart from '../../../../../atoms/PaperPart.js';

import BackButton from '../../../../../atoms/Buttons/BackButton.js';
import PositiveButton from '../../../../../atoms/Buttons/PositiveButton.js';
import SimpleTable from '../../../../../molecules/SimpleTable.js';
import {renderCheckbox, renderTextField} from '../../../../../atoms/CustomPart.js';
import {
  searchDb, execApexRestApi, changeDateFormat, downloadAttachedFile,
  getCodeFromGeneralPurposeMap, getComboName, getToDate, changeDateFormatter,
  API_CODE, getResponseResult,
} from '../../../../../../common/common.js';
import * as validateRule from '../../../../../validateRule.js';

import {attachorderOperations} from '../../../../../../reducks/attachorder';
import {commonOperations} from '../../../../../../reducks/common';


const styles = (theme) => ({
});

const wideHeaderTableStyle = {
  tableHeader: {
    width: '45%',
  },
};

const formFields = [
  {name: 'IsetuIraiNo__c', value: 'IsetuIraiNo__c', ref: 'IsetuIraiDesignDocument__r'},
  {name: 'KoziPlacePrefectures__c', value: 'KoziPlacePrefectures__c'},
  {name: 'KoziPlaceMunicipalities__c', value: 'KoziPlaceMunicipalities__c'},
  {name: 'KoziPlacePlace__c', value: 'KoziPlacePlace__c'},
  {name: 'DntNo__c', value: 'DntNo__c', ref: 'IsetuIraiDesignDocument__r'},
  {name: 'Mark__c', value: 'Mark__c', ref: 'IsetuIraiDesignDocument__r'},
  {name: 'TargetStbNone__c', value: 'TargetStbNone__c'},
  {name: 'KyogaKoziScheduleDate__c', value: 'KyogaKoziScheduleDate__c'},
];

const dataListBasicInfo = [
  {key: 'key1412-2-1', name: '工事概要', value: 'KoziOverview__c', ref: 'IsetuIraiDesignDocument__r'},
  {key: 'key1412-2-2', name: '工事予定時期', value: 'KoziScheduleSeason__c', ref: 'IsetuIraiDesignDocument__r'},
  {key: 'key1412-2-3', name: '工事予定時期補足', value: 'KoziScheduleSeasonHosoku__c', ref: 'IsetuIraiDesignDocument__r'},
  {key: 'key1412-2-4', name: '設計書番号', value: 'IsetuIraiDesignDocumentNo__c', ref: 'IsetuIraiDesignDocument__r'},
  {key: 'key1412-2-5', name: '移設完了希望日（設計時）', value: 'IsetuCompHopeDateTimeOfDesign__c', ref: 'IsetuIraiDesignDocument__r', format: 'date'},
  {key: 'key1412-2-6', name: '連絡事項（設計時）', value: 'MessageTimeOfDesign__c', ref: 'IsetuIraiDesignDocument__r'},
  {key: 'key1412-2-6-2', name: '共架者別連絡事項', value: 'EachKyogasyaMessage__c'},
];

const dataListDesignInfo = [
  {key: 'key1412-3-1', name: '設計会社／所属', value: 'DesignCompanySyozoku__c', ref: 'IsetuIraiDesignDocument__r'},
  {key: 'key1412-3-2', name: '設計担当者名', value: 'DesignTantoName__c', ref: 'IsetuIraiDesignDocument__r'},
  {key: 'key1412-3-3', name: '設計電話番号', value: 'DesignTel__c', ref: 'IsetuIraiDesignDocument__r'},
  {key: 'key1412-3-4', name: '設計メールアドレス', value: 'DesignMailAddress__c', ref: 'IsetuIraiDesignDocument__r'},
];

const dataListConstructionDate = [
  {key: 'key1412-4-2', name: '移設完了希望日（工事日連絡時）', value: 'IsetuCompHopeDateTimeOfContact__c', ref: 'IsetuIraiDesignDocument__r', format: 'date'},
  {key: 'key1412-4-3', name: '電力建柱工事日', value: 'DrKenTyKoziDate__c', ref: 'IsetuIraiDesignDocument__r', format: 'date', dispStatus: 'Confirm'},
  {key: 'key1412-4-4', name: '電力移線工事日', value: 'DrIsnKoziDate__c', ref: 'IsetuIraiDesignDocument__r', format: 'date', dispStatus: 'Confirm'},
  {key: 'key1412-4-5', name: '電力抜柱工事日', value: 'DrKenBaTyKoziDate__c', ref: 'IsetuIraiDesignDocument__r', format: 'date', dispStatus: 'Confirm'},
  {key: 'key1412-4-6', name: '電力工事日連絡日', value: 'NWKoziDateContactDateConfirm__c', format: 'date', dispStatus: 'Confirm'},
  {key: 'key1412-4-7', name: '電力建柱工事日（変更）', value: 'DrKenTyKoziDateChange__c', ref: 'IsetuIraiDesignDocument__r', format: 'date', dispStatus: 'Change'},
  {key: 'key1412-4-8', name: '電力移線工事日（変更）', value: 'DrIsnKoziDateChange__c', ref: 'IsetuIraiDesignDocument__r', format: 'date', dispStatus: 'Change'},
  {key: 'key1412-4-9', name: '電力抜柱工事日（変更）', value: 'DrKenBaTyKoziDateChange__c', ref: 'IsetuIraiDesignDocument__r', format: 'date', dispStatus: 'Change'},
  {key: 'key1412-4-10', name: '電力工事日（変更）連絡日', value: 'NWKoziDateContactDateChange__c', format: 'date', dispStatus: 'Change'},
  {key: 'key1412-4-11', name: '工事日変更理由', value: 'KoziDateChangeReason__c', ref: 'IsetuIraiDesignDocument__r', dispStatus: 'Change'},
  {key: 'key1412-4-12', name: '工事会社/所属', value: 'KoziCompanySyozoku__c', ref: 'IsetuIraiDesignDocument__r'},
  {key: 'key1412-4-13', name: '工事担当者名', value: 'KoziTantoName__c', ref: 'IsetuIraiDesignDocument__r'},
  {key: 'key1412-4-14', name: '工事電話番号', value: 'KoziTel__c', ref: 'IsetuIraiDesignDocument__r'},
  {key: 'key1412-4-15', name: '工事メールアドレス', value: 'KoziMailAddress__c', ref: 'IsetuIraiDesignDocument__r'},
  {key: 'key1412-4-16', name: '連絡事項（工事日連絡時）', value: 'MessageTimeOfContact__c', ref: 'IsetuIraiDesignDocument__r'},
];

/**
 * 移設依頼基本情報確認 テンプレート。
 * 画面ID:1412
 */
class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      c03Files: [],
      c04Files: [],
    };
  }

  /**
   * 初期処理
   */
  async componentDidMount() {
    await this.getOrderData();
    await this.getAttachedFiles();
  }

  /**
   * システムエラー
   */
  sysErr = () => {
    this.props.doShowMessage({
      message: 'CS0001',
      action: () => {
        this.props.history.push('/login');
      },
    });
  }

  /**
   * 申込情報取得
   */
  getOrderData = async () => {
    // 取得項目
    const appFields = [
      'IsetuIraiDesignDocument__r.KyogaKoziScheduleInputDeadline__c',
      'TargetStbNone__c',
      'KyogaKoziScheduleDate__c',
      'IsetuIraiDesignDocument__r.IraimotoCompanySyozoku__c',
      'IsetuIraiDesignDocument__r.IsetuIraiNo__c',
      'toLabel(KoziPlacePrefectures__c)',
      'KoziPlaceMunicipalities__c',
      'KoziPlacePlace__c',
      'IsetuIraiDesignDocument__r.DntNo__c',
      'IsetuIraiDesignDocument__r.Mark__c',
      'OrderStatus__c',
      'EachKyogasyaMessage__c',
      'IsetuIraiDesignDocument__r.KoziOverview__c',
      'IsetuIraiDesignDocument__r.KoziScheduleSeason__c',
      'IsetuIraiDesignDocument__r.KoziScheduleSeasonHosoku__c',
      'IsetuIraiDesignDocument__r.IsetuIraiDesignDocumentNo__c',
      'IsetuIraiDesignDocument__r.IsetuCompHopeDateTimeOfDesign__c',
      'IsetuIraiDesignDocument__r.MessageTimeOfDesign__c',
      'IsetuIraiDesignDocument__r.DesignCompanySyozoku__c',
      'IsetuIraiDesignDocument__r.DesignTantoName__c',
      'IsetuIraiDesignDocument__r.DesignTel__c',
      'IsetuIraiDesignDocument__r.DesignMailAddress__c',
      'IsetuIraiDesignDocument__r.KoziStop__c',
      'IsetuIraiDesignDocument__r.KoziHold__c',
      'IsetuIraiDesignDocument__r.IsetuCompHopeDateTimeOfContact__c',
      'IsetuIraiDesignDocument__r.DrKenTyKoziDate__c',
      'IsetuIraiDesignDocument__r.DrIsnKoziDate__c',
      'IsetuIraiDesignDocument__r.DrKenBaTyKoziDate__c',
      'NWKoziDateContactDateConfirm__c',
      'IsetuIraiDesignDocument__r.DrKenTyKoziDateChange__c',
      'IsetuIraiDesignDocument__r.DrIsnKoziDateChange__c',
      'IsetuIraiDesignDocument__r.DrKenBaTyKoziDateChange__c',
      'IsetuIraiDesignDocument__r.KoziDateConfirmRegistDate__c',
      'NWKoziDateContactDateChange__c',
      'IsetuIraiDesignDocument__r.KoziDateChangeReason__c',
      'IsetuIraiDesignDocument__r.KoziCompanySyozoku__c',
      'IsetuIraiDesignDocument__r.KoziTantoName__c',
      'IsetuIraiDesignDocument__r.KoziTel__c',
      'IsetuIraiDesignDocument__r.KoziMailAddress__c',
      'IsetuIraiDesignDocument__r.MessageTimeOfContact__c',
      'IsetuIraiDesignDocument__r.KoziDateChangeConfirmRegistDate__c',
      // 画面表示以外
      'Id',
      'OrderNo__c',
      'IsetuIraiDesignDocument__r.IsetuIraiStatus__c',
      'IsetuIraiDesignDocument__c',
      'DesignIraiAcceptedDate__c',
      'IsetuIraiContentsAcceptedDate__c',
      'IstIriKoziDateConfirmAccepted__c',
      'IstIriKoziDateChangeAccepted__c',
      'LastModifiedDate',
    ];
    // 取得条件
    const conditions = {
      Id: this.props.orderId,
    };
    // サブクエリ：契約電柱
    const subquery = [{
      'fields': ['Id'],
      'conditions': {
        'RecordType.DeveloperName': 'IsetuIrai',
      },
      'table': 'ContractDnts__r',
    }];
    await this.props.doGetIsetuIraiOrderSubList(
        conditions, appFields, null, subquery);
    if (!this.props.hasOwnProperty('orderSubList') ||
      this.props.orderSubList.length <= 0) {
      this.sysErr();
      return;
    }
  }

  /**
   * 添付ファイル取得
   */
  getAttachedFiles = async () => {
    const {userInfo, designDocumentId, generalPurposeMap} = this.props;
    const conditions = {
      'Id': designDocumentId,
      'RecordType.DeveloperName': 'IsetuIraiDesignDocument',
    };
    const appFields = [
      'Id',
    ];
    const subquery = [
      {
        'fields': [
          'ContentDocument.LatestPublishedVersion.AttachedFileType__c',
          'ContentDocument.LatestPublishedVersion.VersionData',
          'ContentDocument.LatestPublishedVersion.PathOnClient',
          'ContentDocument.LatestPublishedVersion.FileType',
          'ContentDocument.LatestPublishedVersion.ContentDocumentId',
        ],
        'table': 'ContentDocumentLinks',
      },
    ];
    // 移設依頼設計書に紐づく添付ファイルデータ取得
    const retApp = await searchDb(
        userInfo, 'DesignDocument/getSub', conditions, appFields,
        null, null, subquery);
    if (!retApp || retApp.data.body.errorCode != '00000') {
      this.sysErr();
      return;
    }
    if (retApp.data.body.data &&
      retApp.data.body.data[0].ContentDocumentLinks &&
      retApp.data.body.data[0].ContentDocumentLinks.records &&
      retApp.data.body.data[0].ContentDocumentLinks.records.length > 0) {
      const contentDocuments = [];
      for (const links of
        retApp.data.body.data[0].ContentDocumentLinks.records) {
        const contentDocument = {
          type:
            links.ContentDocument.LatestPublishedVersion.AttachedFileType__c,
          url: links.ContentDocument.LatestPublishedVersion.VersionData,
          name: links.ContentDocument.LatestPublishedVersion.PathOnClient,
        };
        contentDocuments.push(contentDocument);
      }
      console.dir(contentDocuments);
      const attachedFileTypeC03 = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeC03', 3);
      const attachedFileTypeC04 = getCodeFromGeneralPurposeMap(generalPurposeMap, 'AttachedFileTypeC04', 3);
      if (contentDocuments) {
        const c03Files = contentDocuments.filter(
            (record) => record.type == attachedFileTypeC03);
        const c04Files = contentDocuments.filter(
            (record) => record.type == attachedFileTypeC04);
        this.setState({
          c03Files: c03Files,
          c04Files: c04Files,
        });
      }
    }
  }

  /**
   * 終了処理
   */
  async componentWillUnmount() {
    const {reset} = this.props;
    reset();
  }

  /**
   * 登録処理
   */
  doRegist = async () => {
    const {
      isKoziDateChange,
      orderSubList,
      values,
    } = this.props;

    if (!values['TargetStbNone__c']) {
      // 対象設備無しが未チェックの場合、移設工事予定日が未入力の場合エラーとする。
      const error = validateRule.required(values['KyogaKoziScheduleDate__c']);
      if (error) {
        this.props.doShowMessage({
          message: {
            id: 'CE0024',
            values: ['対象設備無し', '未チェック', '移設工事予定日'],
          },
        });
        return false;
      }
    }
    const orderData = orderSubList[0];

    // 移設工事予定日が存在する場合、日付範囲チェック
    const kyogaKoziScheduleDate = values['KyogaKoziScheduleDate__c'];
    if (kyogaKoziScheduleDate) {
      const isetuIrai = orderData.IsetuIraiDesignDocument__r;

      /** チェック対象の建柱工事日に確定時の値を設定 */
      let drKenTyKoziDate = isetuIrai.DrKenTyKoziDate__c;
      let drKenTyKoziDateName = '建柱工事日';

      /** チェック対象の抜柱工事日に確定時の値を設定 */
      let drKenBaTyKoziDate = isetuIrai.DrKenBaTyKoziDate__c;
      let drKenBaTyKoziDateName = '抜柱工事日';

      // 移設依頼.工事日変更確定登録日が設定済の場合
      if (isKoziDateChange) {
        // 建柱工事日（変更）が設定されている場合、変更時の値に変更
        const drKenTyKoziDateChange = isetuIrai.DrKenTyKoziDateChange__c;
        if (drKenTyKoziDateChange) {
          drKenTyKoziDate = drKenTyKoziDateChange;
          drKenTyKoziDateName += '（変更）';
        }

        // 抜柱工事日（変更）が設定されている場合、変更時の値に変更
        const drKenBaTyKoziDateChange = isetuIrai.DrKenBaTyKoziDateChange__c;
        if (drKenBaTyKoziDateChange) {
          drKenBaTyKoziDate = drKenBaTyKoziDateChange;
          drKenBaTyKoziDateName += '（変更）';
        }
      }

      // 共通のチェックエラーメッセージを生成しておく
      const message = {
        id: 'CE0021',
        values: [drKenTyKoziDateName, drKenBaTyKoziDateName],
      };

      // 建柱工事日が存在する場合、予定日が建柱工事日以降であるか判定
      if (drKenTyKoziDate && drKenTyKoziDate > kyogaKoziScheduleDate) {
        this.props.doShowMessage({message});
        return false;
      }

      // 建柱工事日が存在する場合、予定日が抜柱工事日以前であるか判定
      if (drKenBaTyKoziDate && drKenBaTyKoziDate < kyogaKoziScheduleDate) {
        this.props.doShowMessage({message});
        return false;
      }
    }

    this.props.doShowMessage({
      message: {
        id: 'CC0005',
        values: ['登録'],
      },
      action: () => {
        this.updateOrder();
      },
    });
  }

  /**
   * 申込更新処理
   */
  updateOrder = async () => {
    const {
      userInfo,
      orderId,
      orderSubList,
      values,
    } = this.props;
    const orderData = orderSubList[0];
    if (orderData['TargetStbNone__c'] != values['TargetStbNone__c'] ||
      orderData['KyogaKoziScheduleDate__c'] != values['KyogaKoziScheduleDate__c']) {
      // 取得条件
      const conditions = [{
        Id: orderId,
        LastModifiedDate: orderData['LastModifiedDate'],
      }];
      // 更新内容
      const updateInfo = {
        Id: orderId,
        TargetStbNone__c: values['TargetStbNone__c'],
        KyogaKoziScheduleDate__c: values['KyogaKoziScheduleDate__c'],
      };
      if (values['TargetStbNone__c']) updateInfo['OrderStatus__c'] = '90';
      if (!orderData['DesignIraiAcceptedDate__c']) updateInfo['DesignIraiAcceptedDate__c'] = getToDate();
      if (values['Status'] == '予定連絡') {
        if (!orderData['IsetuIraiContentsAcceptedDate__c']) updateInfo['IsetuIraiContentsAcceptedDate__c'] = getToDate();
      } else if (values['Status'] == '工事日確定連絡') {
        if (!orderData['IsetuIraiContentsAcceptedDate__c']) updateInfo['IsetuIraiContentsAcceptedDate__c'] = getToDate();
        if (!orderData['IstIriKoziDateConfirmAccepted__c']) updateInfo['IstIriKoziDateConfirmAccepted__c'] = getToDate();
      } else if (values['Status'] == '工事日変更連絡') {
        if (!orderData['IsetuIraiContentsAcceptedDate__c']) updateInfo['IsetuIraiContentsAcceptedDate__c'] = getToDate();
        if (!orderData['IstIriKoziDateConfirmAccepted__c']) updateInfo['IstIriKoziDateConfirmAccepted__c'] = getToDate();
        if (!orderData['IstIriKoziDateChangeAccepted__c']) updateInfo['IstIriKoziDateChangeAccepted__c'] = getToDate();
      } else {
        if (orderData.IsetuIraiDesignDocument__r &&
          orderData.IsetuIraiDesignDocument__r.DrIsnKoziDateChange__c) {
          if (!orderData['IsetuIraiContentsAcceptedDate__c']) updateInfo['IsetuIraiContentsAcceptedDate__c'] = getToDate();
          if (!orderData['IstIriKoziDateConfirmAccepted__c']) updateInfo['IstIriKoziDateConfirmAccepted__c'] = getToDate();
          if (!orderData['IstIriKoziDateChangeAccepted__c']) updateInfo['IstIriKoziDateChangeAccepted__c'] = getToDate();
        } else {
          if (!orderData['IsetuIraiContentsAcceptedDate__c']) updateInfo['IsetuIraiContentsAcceptedDate__c'] = getToDate();
          if (!orderData['IstIriKoziDateConfirmAccepted__c']) updateInfo['IstIriKoziDateConfirmAccepted__c'] = getToDate();
        }
      }

      const orderList = [updateInfo];
      const body = {
        orderConditions: conditions,
        orderList: orderList,
      };
      const result = await execApexRestApi(
          userInfo, 'ApiIsetuIrai/isetuIraiUpdate', {record: body});
      const resResult = getResponseResult(result, ['移設依頼情報', '登録']);
      if (resResult.errorCode != API_CODE.SUCCESS) {
        this.props.doShowMessage({
          message: resResult.messages,
        });
        return;
      }
      this.props.doShowMessage({
        message: {
          id: 'CI0009',
          values: ['登録'],
        },
        action: () => {
          // 申込データの再取得
          this.getOrderData();
        },
      });
    } else {
      this.props.doShowMessage({
        message: {
          id: 'CI0009',
          values: ['登録'],
        },
        action: () => {
          // 申込データの再取得
          this.getOrderData();
        },
      });
    }
  }

  /**
   * ダウンロード
   * @param {object} file ファイル
   */
  doDownloadAttachedFile = async (file) => {
    const {userInfo} = this.props;
    downloadAttachedFile(userInfo, file.url, file.type, file.name);
  }

  render() {
    const {
      classes,
      dataListBasicInfo,
      dataListDesignInfo,
      dataListConstructionDate,
      kyogaKoziScheduleInputDeadline,
      isDispConfirm,
      isComplete,
      handleSubmit,
    } = this.props;

    let dataListBasic = [];
    Object.assign(dataListBasic, dataListBasicInfo);
    // 添付ファイル
    let cntC03 = 0;
    let cntC04 = 0;
    dataListBasic = dataListBasic.filter((info) => info.key != 'key1412-2-8' && info.key != 'key1412-2-9');
    if (this.state.c03Files) {
      for (const c03File of this.state.c03Files) {
        let name = '';
        if (cntC03 == 0) {
          name = '添付（移設依頼書）';
        }
        const files = {
          key: 'key1412-2-8-' + cntC03, name: name, value: c03File.name,
          link: '#',
        };
        files.onClick = () => {
          this.doDownloadAttachedFile(c03File);
        };
        dataListBasic.push(files);
        cntC03++;
      }
    } else {
      dataListBasic.push({key: 'key1412-2-8', name: '添付（移設依頼書）', value: ''});
    }
    if (this.state.c04Files && this.state.c04Files.length > 0) {
      for (const c04File of this.state.c04Files) {
        let name = '';
        if (cntC04 == 0) {
          name = '添付（その他）';
        }
        const files = {
          key: 'key1412-2-9-' + cntC04, name: name, value: c04File.name,
          link: '#',
        };
        files.onClick = () => {
          this.doDownloadAttachedFile(c04File);
        };
        dataListBasic.push(files);
        cntC04++;
      }
    } else {
      dataListBasic.push({key: 'key1412-2-9', name: '添付（その他）', value: ''});
    }

    const footerBtn = isDispConfirm && !isComplete ? (
      <Grid container
        justifyContent="center"
        alignItems="flex-start"
        spacing={1}
      >
        <Grid key="btn1" item>
          <PositiveButton
            startIcon={<SaveIcon />}
            onClick={handleSubmit(this.doRegist)}
            variant="contained"
            size="large"
            type="button"
          >
            <span>登録</span>
          </PositiveButton>
        </Grid>

        <Grid key="btn3" item>
          <PositiveButton
            id="btnReceive"
            onClick={() => {
              this.props.history.push({pathname: '/IsetuIrai/IsetuIraiCheck/IsetuIraiScheduleContact/DetailCheck'});
            }}
            variant="contained"
            size="large"
            type="submit"
          >
            <span>依頼内容明細確認</span>
          </PositiveButton>
        </Grid>
        <Grid key="btn4" item>
          <BackButton showWarning={true} props={this.props}
            onClick={async () => {
              await this.props.doClearOrderSubList();
              await this.props.doClearAttachedFiles();
            }}
          />
        </Grid>
      </Grid>
    ) :
      (
        <Grid container
          justifyContent="center"
          alignItems="flex-start"
          spacing={1}
        >

          <Grid key="btn3" item>
            <PositiveButton
              id="btnReceive"
              onClick={() => {
                this.props.history.push({pathname: '/IsetuIrai/IsetuIraiCheck/IsetuIraiScheduleContact/DetailCheck'});
              }}
              variant="contained"
              size="large"
              type="submit"
            >
              <span>依頼内容明細確認</span>
            </PositiveButton>
          </Grid>
          <Grid key="btn4" item>
            <BackButton showWarning={true} props={this.props}
              onClick={async () => {
                await this.props.doClearOrderSubList();
                await this.props.doClearAttachedFiles();
              }}
            />
          </Grid>
        </Grid>
      );

    return isDispConfirm ?
      (
        <form noValidate autoComplete="off">
          <MainContainer
            props={this.props}
            footerBtn={footerBtn}
          >

            <PaperPart>

              <Grid
                container
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <Typography variant="h6">電力側工事場所</Typography>
              </Grid>

              <Grid
                container
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >

                <Grid key="key1412-1-1" item xs={12} sm={6} md={3}>
                  <Field
                    name="IsetuIraiNo__c"
                    className={classes.fields}
                    component={renderTextField}
                    label="一連番号"
                    fullWidth
                    type="text"
                    InputLabelProps={{shrink: true}}
                    InputProps={{
                      readOnly: true,
                    }}
                    validate={[
                    ]}
                  />
                </Grid>

                <Grid key="key1412-1-2" item xs={12} sm={6} md={3}>
                  <Field
                    name="KoziPlacePrefectures__c"
                    className={classes.fields}
                    component={renderTextField}
                    label="都道府県"
                    fullWidth
                    type="text"
                    InputProps={{
                      readOnly: true,
                    }}
                    validate={[
                    ]}
                  />
                </Grid>

                <Grid key="key1412-1-3" item xs={12} sm={6} md={3}>
                  <Field
                    name="KoziPlaceMunicipalities__c"
                    className={classes.fields}
                    component={renderTextField}
                    label="市区町村"
                    fullWidth
                    type="text"
                    InputProps={{
                      readOnly: true,
                    }}
                    validate={[
                    ]}
                  />
                </Grid>

                <Grid key="key1412-1-4" item xs={12} sm={6} md={3}>
                  <Field
                    name="KoziPlacePlace__c"
                    className={classes.fields}
                    component={renderTextField}
                    label="場所"
                    fullWidth
                    type="text"
                    InputProps={{
                      readOnly: true,
                    }}
                    validate={[
                    ]}
                  />
                </Grid>

                <Grid key="key1412-1-5" item xs={12} sm={6} md={3}>
                  <Field
                    name="DntNo__c"
                    className={classes.fields}
                    component={renderTextField}
                    label="電柱番号"
                    fullWidth
                    type="text"
                    InputProps={{
                      readOnly: true,
                    }}
                    validate={[
                    ]}
                  />
                </Grid>

                <Grid key="key1412-1-6" item xs={12} sm={6} md={3}>
                  <Field
                    name="Mark__c"
                    className={classes.fields}
                    component={renderTextField}
                    label="目標"
                    fullWidth
                    type="text"
                    InputProps={{
                      readOnly: true,
                    }}
                    validate={[
                    ]}
                  />
                </Grid>

                <Grid key="key1412-1-7" item xs={12} sm={6} md={3}>
                  <Field
                    name="Status"
                    className={classes.fields}
                    component={renderTextField}
                    label="ステータス"
                    fullWidth
                    type="text"
                    InputProps={{
                      readOnly: true,
                    }}
                    validate={[]}
                  />
                </Grid>

              </Grid>

            </PaperPart>

            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid item xs={12} md={6}>
                <PaperPart>
                  <Typography variant="h6">電力側工事概要</Typography>
                  <SimpleTable
                    styles={wideHeaderTableStyle}
                    dataList={dataListBasic} />

                  <Typography variant="h6">設計者</Typography>
                  <SimpleTable dataList={dataListDesignInfo} />
                </PaperPart>
              </Grid>

              <Grid item xs={12} md={6}>
                <PaperPart>
                  <Typography variant="h6">工事日確定変更連絡</Typography>
                  <SimpleTable
                    styles={wideHeaderTableStyle}
                    dataList={dataListConstructionDate} />
                </PaperPart>
              </Grid>
            </Grid>

            <PaperPart>

              <Grid
                container
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item xs={12}>
                  <Typography variant="h6">移設工事予定日</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="h6">予定日入力期限</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="h6">{kyogaKoziScheduleInputDeadline}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}></Grid>

                <Grid key="key1412-5-1" item xs={12} sm={6} md={3}>
                  <Field className={classes.checkbox}
                    id="TargetStbNone__c"
                    name="TargetStbNone__c"
                    component={renderCheckbox}
                    label="対象設備無し"
                    disabled={isComplete}
                    validate={[]}
                  />
                </Grid>

                <Grid key="key1412-5-2" item xs={12} sm={6} md={3}>
                  <Field
                    id="KyogaKoziScheduleDate__c"
                    name="KyogaKoziScheduleDate__c"
                    accessibilitylabel="KyogaKoziScheduleDate__c"
                    label="移設工事予定日"
                    component={renderTextField}
                    className={classes.textFieldDate}
                    required={false}
                    fullWidth
                    type="date"
                    disabled={isComplete}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    validate={[validateRule.date]}
                  />
                </Grid>

              </Grid>

            </PaperPart>

          </MainContainer>
        </form>
      ) :
      (
        <form noValidate autoComplete="off">
          <MainContainer
            props={this.props}
            footerBtn={footerBtn}
          >

            <PaperPart>

              <Grid
                container
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <Typography variant="h6">電力側工事場所</Typography>
              </Grid>

              <Grid
                container
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >

                <Grid key="key1412-1-1" item xs={12} sm={6} md={3}>
                  <Field
                    name="IsetuIraiNo__c"
                    className={classes.fields}
                    component={renderTextField}
                    label="一連番号"
                    fullWidth
                    type="text"
                    InputLabelProps={{shrink: true}}
                    InputProps={{
                      readOnly: true,
                    }}
                    validate={[
                    ]}
                  />
                </Grid>

                <Grid key="key1412-1-2" item xs={12} sm={6} md={3}>
                  <Field
                    name="KoziPlacePrefectures__c"
                    className={classes.fields}
                    component={renderTextField}
                    label="都道府県"
                    fullWidth
                    type="text"
                    InputProps={{
                      readOnly: true,
                    }}
                    validate={[
                    ]}
                  />
                </Grid>

                <Grid key="key1412-1-3" item xs={12} sm={6} md={3}>
                  <Field
                    name="KoziPlaceMunicipalities__c"
                    className={classes.fields}
                    component={renderTextField}
                    label="市区町村"
                    fullWidth
                    type="text"
                    InputProps={{
                      readOnly: true,
                    }}
                    validate={[
                    ]}
                  />
                </Grid>

                <Grid key="key1412-1-4" item xs={12} sm={6} md={3}>
                  <Field
                    name="KoziPlacePlace__c"
                    className={classes.fields}
                    component={renderTextField}
                    label="場所"
                    fullWidth
                    type="text"
                    InputProps={{
                      readOnly: true,
                    }}
                    validate={[
                    ]}
                  />
                </Grid>

                <Grid key="key1412-1-5" item xs={12} sm={6} md={3}>
                  <Field
                    name="DntNo__c"
                    className={classes.fields}
                    component={renderTextField}
                    label="電柱番号"
                    fullWidth
                    type="text"
                    InputProps={{
                      readOnly: true,
                    }}
                    validate={[
                    ]}
                  />
                </Grid>

                <Grid key="key1412-1-6" item xs={12} sm={6} md={3}>
                  <Field
                    name="Mark__c"
                    className={classes.fields}
                    component={renderTextField}
                    label="目標"
                    fullWidth
                    type="text"
                    InputProps={{
                      readOnly: true,
                    }}
                    validate={[
                    ]}
                  />
                </Grid>

                <Grid key="key1412-1-7" item xs={12} sm={6} md={3}>
                  <Field
                    name="Status"
                    className={classes.fields}
                    component={renderTextField}
                    label="ステータス"
                    fullWidth
                    type="text"
                    InputProps={{
                      readOnly: true,
                    }}
                    validate={[]}
                  />
                </Grid>

              </Grid>

            </PaperPart>

            <Grid
              container
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid item xs={12} md={6}>
                <PaperPart>
                  <Typography variant="h6">電力側工事概要</Typography>
                  <SimpleTable
                    styles={wideHeaderTableStyle}
                    dataList={dataListBasic} />

                  <Typography variant="h6">設計者</Typography>
                  <SimpleTable dataList={dataListDesignInfo} />
                </PaperPart>
              </Grid>

              <Grid item xs={12} md={6}>
                <PaperPart>
                  <Typography variant="h6">工事日確定変更連絡</Typography>
                  <SimpleTable
                    styles={wideHeaderTableStyle}
                    dataList={dataListConstructionDate} />
                </PaperPart>
              </Grid>
            </Grid>

          </MainContainer>
        </form>
      );
  }
}

Container.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  userInfo: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  doShowMessage: PropTypes.func.isRequired,
  orderId: PropTypes.string.isRequired,
  designDocumentId: PropTypes.string.isRequired,
  generalPurposeMap: PropTypes.object,
  doClearOrderSubList: PropTypes.func,
  reset: PropTypes.func.isRequired,
  doGetIsetuIraiOrderSubList: PropTypes.func,
  doUpdateOrder: PropTypes.func,
  orderSubList: PropTypes.array,
  doGetAttachedFiles: PropTypes.func,
  doClearAttachedFiles: PropTypes.func,
  attachedFileList: PropTypes.array,
  dataListBasicInfo: PropTypes.array,
  dataListDesignInfo: PropTypes.array,
  dataListConstructionDate: PropTypes.array,
  values: PropTypes.object,
  kyogaKoziScheduleInputDeadline: PropTypes.string,
  isDispConfirm: PropTypes.bool,
  isKoziDateChange: PropTypes.bool,
  isComplete: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const initValues = {};
  const basicInfo = [];
  const designInfo = [];
  const constructionInfo = [];
  const orderData = state.attachorder.orderSubList ?
    state.attachorder.orderSubList[0] : undefined;
  const generalPurposeMap = state.common.generalPurposeMap;

  /** 工事日が確定済みかどうか（申込ステータス＝竣工待ち/工事日確定済、完結、完結（取止） */
  let isDispConfirm = false;

  /** 申込.NW工事日連絡日_変更が確定済かどうか */
  let isDispChange = false;

  /** 移設依頼.工事日確定登録日が確定済かどうか */
  let isKoziDateConfirm = false;

  /** 移設依頼.工事日変更確定登録日が確定済かどうか */
  let isKoziDateChange = false;

  /** 申込ステータス */
  let orderStatus = null;

  /** 完結しているかどうか(申込ステータスが完結または完結（取止め）) */
  let isComplete = false;

  if (orderData) {
    orderStatus = orderData.OrderStatus__c;

    for (const field of formFields) {
      initValues[field.name] = field.ref ?
        orderData[field.ref][field.value] : orderData[field.value];
    }
    // 移設依頼ステータス判定
    if (orderStatus == '50') {
      initValues['Status'] = '予定連絡';
    } else if (orderStatus == '51') {
      if (orderData.IsetuIraiDesignDocument__r &&
        orderData.IsetuIraiDesignDocument__r.DrIsnKoziDateChange__c) {
        initValues['Status'] = '工事日変更連絡';
      } else {
        initValues['Status'] = '工事日確定連絡';
      }
    } else {
      if (generalPurposeMap &&
        orderData.IsetuIraiDesignDocument__r &&
        orderData.IsetuIraiDesignDocument__r.IsetuIraiStatus__c) {
        initValues['Status'] = getComboName(generalPurposeMap, 'IsetuIraiStatus', orderData.IsetuIraiDesignDocument__r.IsetuIraiStatus__c);
      }
      isComplete = (orderStatus == '90' || orderStatus == '92');
    }
    // isDispConfirm = orderData.NWKoziDateContactDateConfirm__c ||
    //  orderData.NWKoziDateContactDateChange__c ? true : false;
    isDispConfirm = (isComplete || orderStatus == '51');
    isDispChange = orderData.NWKoziDateContactDateChange__c ? true : false;

    isKoziDateConfirm = isDispConfirm && orderData.IsetuIraiDesignDocument__r &&
      orderData.IsetuIraiDesignDocument__r.
          KoziDateConfirmRegistDate__c ? true : false;

    isKoziDateChange = orderData.IsetuIraiDesignDocument__r &&
      orderData.IsetuIraiDesignDocument__r.
          KoziDateChangeConfirmRegistDate__c ? true : false;
  }
  // 予定日入力期限
  let kyogaKoziScheduleInputDeadline = orderData && orderData.IsetuIraiDesignDocument__r ? changeDateFormat(orderData.IsetuIraiDesignDocument__r.KyogaKoziScheduleInputDeadline__c) : '';

  for (const field of dataListBasicInfo) {
    if (field.key == 'key1412-2-2') { // 工事予定時期
      basicInfo.push({key: field.key, name: field.name, format: field.format, value: orderData && field.ref ? changeDateFormatter(orderData[field.ref][field.value], 'YYYY年MM月') : ''});
    } else {
      basicInfo.push({key: field.key, name: field.name, format: field.format, value: orderData ? field.ref ? orderData[field.ref][field.value] : orderData[field.value] : ''});
    }
  }
  // 工事対象本数
  basicInfo.push({key: 'key1412-2-7', name: '工事対象本数', value: orderData ? orderData.ContractDnts__r ? orderData.ContractDnts__r.totalSize : 0 : ''});

  for (const field of dataListDesignInfo) {
    designInfo.push({key: field.key, name: field.name, format: field.format, value: orderData ? field.ref ? orderData[field.ref][field.value] : orderData[field.value] : ''});
  }

  if (isDispConfirm) {
    // 工事保留／中止  確定時以降表示
    constructionInfo.push({key: 'key1412-4-1', name: '工事保留／中止', value: orderData ? orderData.IsetuIraiDesignDocument__r.KoziStop__c ? '中止' : orderData.IsetuIraiDesignDocument__r.KoziHold__c ? '保留' : '' : ''});
  }

  for (const field of dataListConstructionDate) {
    if (field.dispStatus) {
      if (field.dispStatus == 'Confirm' && isDispConfirm) {
        // 確定時以降表示
        if (isKoziDateConfirm) {
          constructionInfo.push({key: field.key, name: field.name, format: field.format, value: orderData ? field.ref ? orderData[field.ref][field.value] : orderData[field.value] : ''});
        } else {
          // 工事日が未確定の場合、空で表示
          constructionInfo.push({key: field.key, name: field.name, format: field.format, value: ''});
        }
      } else if (field.dispStatus == 'Change' && isDispChange) {
        // 工事日（変更）が確定済の場合は項目を表示

        if (isKoziDateChange && (orderStatus == '51' || orderStatus == '90')) {
          // 移設依頼.工事日変更確定登録日が設定済で、申込ステータスが「竣工待ち/工事日確定済」または「完結」の場合、値を表示
          constructionInfo.push({key: field.key, name: field.name, format: field.format, value: orderData ? field.ref ? orderData[field.ref][field.value] : orderData[field.value] : ''});
        } else {
          // それ以外の場合、空で表示
          constructionInfo.push({key: field.key, name: field.name, format: field.format, value: ''});
        }
      }
    } else {
      constructionInfo.push({
        key: field.key, name: field.name,
        format: field.format,
        value: orderData ? field.ref ? orderData[field.ref][field.value] : orderData[field.value] : '',
      });
    }
  }

  // 添付ファイル
  basicInfo.push({key: 'key1412-2-8', name: '添付（移設依頼書）'});
  basicInfo.push({key: 'key1412-2-9', name: '添付（その他）'});

  return {
    initialValues: initValues,
    isDispConfirm: isDispConfirm,
    isKoziDateChange: isKoziDateChange,
    userInfo: state.auth.userInfo,
    generalPurposeMap: state.common.generalPurposeMap,
    dataListBasicInfo: basicInfo,
    dataListDesignInfo: designInfo,
    dataListConstructionDate: constructionInfo,
    orderId: state.attachorder.orderId,
    orderSubList: state.attachorder.orderSubList,
    attachedFileList: state.common.attachedFileList,
    designDocumentId: state.isetuIrai.designDocumentId,
    kyogaKoziScheduleInputDeadline: kyogaKoziScheduleInputDeadline,
    isComplete: isComplete,
  };
};

const mapDispatchToProps = {
  doGetIsetuIraiOrderSubList: attachorderOperations.doGetIsetuIraiOrderSubList,
  doUpdateOrder: attachorderOperations.doUpdateOrder,
  doGetAttachedFiles: commonOperations.doGetAttachedFiles,
  doClearAttachedFiles: commonOperations.doClearAttachedFiles,
  doClearOrderSubList: attachorderOperations.doClearOrderSubList,
};

const FORM_NAME = 'IsetuIraiBasicInfCheck';

Container = reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(connect((state) => {
  return {
    values: getFormValues(FORM_NAME)(state),
  };
})(Container));

export default withStyles(styles)(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Container),
);
